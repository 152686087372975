import React from 'react';
import './LoadingSpinner.css';
// import { applyColors } from '../../styles/applyColors';
// applyColors()

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner"/>
    </div>
  );
};

export default LoadingSpinner;