import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatInterface.css';
// import { applyColors } from '../../../styles/applyColors';
// applyColors()

const CHAT_API_URL = 'https://si3yq9091n2y.share.zrok.io';

const chat_api = axios.create({
  baseURL: CHAT_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

const ChatInterface = ({ subtitle }) => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setChatHistory([]);
  }, [subtitle]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (message.trim()) {
      const newMessage = { role: 'human', content: message };
      setChatHistory(prevHistory => [...prevHistory, newMessage]);
      setMessage('');
      setIsLoading(true);

      try {
        const response = await chat_api.get('/chat', {
          params: {
            question: message,
            context: subtitle || '',
            // history: JSON.stringify(chatHistory)
          }
        });
        
        setChatHistory(prevHistory => [
          ...prevHistory,
          { role: 'ai', content: response.data }
        ]);
      } catch (error) {
        console.error('채팅 요청 중 오류가 발생했습니다:', error);
        alert('채팅 요청 중 오류가 발생했습니다. 다시 시도해 주세요.');
        setChatHistory(prevHistory => prevHistory.slice(0, -1));
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='chat-fixed'>
      <div className="chat-interface">
        <div className="chat-history">
          {chatHistory.map((chat, index) => (
            <div key={index} className={`chat-message ${chat.role}`}>
              {/* {chat.role === 'ai' && (
                <img src="/sf_logo.png" alt="AI Logo" className="ai-logo" />
              )} */}
              <span>{chat.content}</span>
            </div>
          ))}
          {isLoading && (
            <div className="chat-message ai">
              {/* <img src="/sf_logo.png" alt="AI Logo" className="ai-logo" /> */}
              <span>답변을 생성 중입니다...</span>
            </div>
          )}
        </div>
        <form onSubmit={handleSendMessage} className="chat-input-form">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="위 강의에 대해 질문해보세요..."
            className="chat-input"
            disabled={isLoading}
          />
          <button type="submit" className="chat-submit" disabled={isLoading}>
            {isLoading ? '답변중...' : '보내기'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatInterface;