import React from 'react';
import './VideoPlayer.css';

function VideoPlayer({ title, url, startTime }) {
  // YouTube URL을 embed URL로 변환
  const getVideoId = (url) => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return urlParams.get('v');
  };

  return (
    <div className="video-container">
      <iframe
        title={title}
        src={`https://www.youtube.com/embed/${getVideoId(url)}?start=${startTime}&autoplay=1&enablejsapi=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default VideoPlayer;