import React, { useState, useEffect, useRef } from 'react';
import './SearchBar.css';
import { applyColors } from '../../../styles/applyColors';
applyColors()

function SearchBar({ onSearch }) {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(query);
  };

  const exampleLectures = [
    "하단에 찾고싶은 수업을 검색해보세요!"
  ]

  const exampleQueries = [
    "0을 계산에 도입한 이유",
    "제논의 역설",
    "복소수 평면에서 'i'의 역할",
    "푸앵카레의 추측"
  ];

  const handleExampleClick = (example) => {
    setQuery(example);
    onSearch(example);
  };

  const handleLogoClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    const checkScrolling = () => {
      const container = lecturesRef.current;
      if (container) {
        setIsScrolling(container.scrollWidth > container.clientWidth);
      }
    };

    checkScrolling();
    window.addEventListener('resize', checkScrolling);

    return () => window.removeEventListener('resize', checkScrolling);
  }, []);

  const lecturesRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  return (
    <div className="main-container">
      <div className="example-lectures-container" ref={lecturesRef}>
        <div className={`example-lectures ${isScrolling ? 'scrolling' : ''}`}>
          {exampleLectures.map((lecture, index) => (
            <span key={index} className="lecture-item">{lecture}</span>
          ))}
        </div>
      </div>
      <div className="search-container">
        <img 
          src="/WithG-logo.png" 
          alt="Logo" 
          className="logo" 
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
        />
        <form onSubmit={handleSubmit} className="search-form">
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="질문을 입력하세요"
            className="search-input"
          />
          <button type="submit" className="search-button">검색</button>
        </form>
      </div>
      <div className="example-queries">
        <span className="section-label">검색 예시 :</span>
        {exampleQueries.map((example, index) => (
          <button
            key={index}
            onClick={() => handleExampleClick(example)}
            className="example-button"
          >
            {example}
          </button>
        ))}
      </div>
    </div>
  );
}

export default SearchBar;